import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Slider, Carousel, CarouselItem } from 'element-ui';
import "amfe-flexible";
import 'element-ui/lib/theme-chalk/index.css';
import '../public/css/reset.css';
import '../public/fonts/iconfont.css';
import './utils/testingEquipment'
Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.config.productionTip = false
Vue.use(Slider)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
