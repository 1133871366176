import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home'
  },

  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'Sobre nosotros',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/ayuda',
    name: 'Ayuda',
    component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue')
  },
  {
    path: '/producto',
    name: 'Producto',
    component: () => import(/* webpackChunkName: "about" */ '../views/Producto.vue')
  },
  {
    path: '/policy',
    name: 'Política de Privacidad',
    component: () => import(/* webpackChunkName: "about" */ '../views/Policy.vue')
  },
  {
    path: '/condiciones',
    name: 'Términos y Condiciones',
    component: () => import(/* webpackChunkName: "about" */ '../views/Condiciones.vue')
  },
  // {
  //   path: '/condiciones',
  //   name: 'Términos y condiciones',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Termservice.vue')
  // },
  {
    path: '*',
    name: 'outher',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
